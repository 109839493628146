<template>
  <vx-card class="locale-setting" :class="[{'top-zero-radius main-box mx-auto p-2 pt-5': $route.name === 'languagesSetting'}]" no-shadow>

    <div class="locale-setting-fields">
      <!--      <h6 class="mb-4 text-h5">{{ $t('setting.Locale.labels.basicSetting') }}</h6>-->

      <custom-select :label="$t('setting.Locale.labels.defaultLanguage')"
                     class="mb-8"
                     :options="languages"
                     :default="locale"
                     v-model="locale" @input="handleSelectLang"/>

      <custom-select :label="$t('setting.Locale.labels.region')"
                     class="mb-8"
                     :options="regionOptions"
                     :default="region"
                     v-model="region"/>

      <template v-if="showCurrencySelect">
        <custom-select :label="$t('setting.Locale.labels.defaultCurrency')"
                       class="mb-8"
                       :options="currencyOptions"
                       :default="currency"
                       v-model="currency"/>
      </template>

      <template v-if="currency.value === 'NAN'">
        <custom-validate-input :label="$t('setting.Locale.labels.defaultCurrency')" class="w-full mb-8" v-model="otherCurrency"/>
      </template>

      <custom-validate-input :label="$t('setting.Locale.labels.defaultCountryCode')" :rtl="$vs.rtl ? false : true" :align="$vs.rtl ? 'left' : 'right'"
                             v-model="countryCode"/>
    </div>
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button id="editLocaleSettingBTN" class="useral-action-button ml-auto mt-2" color="success" @click="updateLocale">{{ $t('setting.buttons.save') }}</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSelect from '@/components/customSelect/customSelect'
import {checkUserPermissions, hideLoading, showLoading} from '../../../../assets/js/functions'
import CustomValidateInput from "../../../../components/customInput/customValidateInput";
import {editLocaleSetting} from "../../../../http/requests/settings/setting";

export default {
  name: 'LocaleSetting',
  components: {CustomValidateInput, CustomSelect},
  metaInfo () {
    return {
      title: this.$t('setting.Locale.title')
    }
  },
  data () {
    return {
      pageTitle: this.$t('setting.Locale.title'),
      defaultLang: '',
      discardChanges: false,
      locale: {
        value: '',
        label: '-'
      },
      currency: {
        value: '',
        label: '-'
      },
      countryCode: {
        value: '',
        label: '-'
      },
      region: {
        label: '-',
        value: ''
      },
      otherCurrency: {
        value: '',
        isValid: false,
      },
      regionOptions: [
        {
          label: 'IRI',
          value: 'IRI'
        },
        {
          label: 'UAE',
          value: 'UAE'
        }
      ],
      languages: this.$locale.getAvailableLocales(),
      showCurrencySelect: true,
      currencyOptions: [
        {
          label: this.$t('setting.Locale.currency.IRT'),
          value: 'IRT'
        },
        {
          label: this.$t('setting.Locale.currency.IRR'),
          value: 'IRR'
        },
        {
          label: this.$t('setting.Locale.currency.USD'),
          value: 'USD'
        },
        {
          label: this.$t('setting.Locale.currency.AED'),
          value: 'AED'
        },
        {
          label: this.$t('setting.Locale.currency.other'),
          value: 'NUN'
        }
      ],
      actions: {
        toolbar: [
          {
            id: 'editLocaleSettingBTN',
            icon: 'SAVE',
            iconPack: 'useral',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'Settings'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLocale: 'currentLocale'
    })
  },
  created () {
    if (this.$route.name === 'Settings') {
      this.actions.leftToolbar.splice(0, 1)
    }
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
    }, 50)
    this.$store.dispatch('setPageTitle', this.$t('setting.Locale.title'))

    this.defaultLang = this.$store.state.setting.clubInfo.club_language

    this.setCurrentUserLocale()
  },
  methods: {
    updateLocale() {
      let currency = this.currency.label
      if (this.currency.value === 'NUN') {
        currency = this.otherCurrency.isValid ? this.otherCurrency.value : ''
      }
      const payload = {
        club_locale: this.region.value,
        club_language: this.locale.lang,
        club_currency: currency,
        club_currency_code: this.currency.value,
        club_default_country_code: this.countryCode.value
      }
      editLocaleSetting(payload).then((response) => {
        this.discardChanges = false
        this.$locale.setLocale(this.locale.lang)
        this.$store.dispatch('setting/setClubInfo', payload)
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.message.title'),
          text: this.$t('setting.Locale.notifications.edit.success'),
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        if (this.$route.name === 'languagesSetting') {
          this.$router.push({name: 'Settings'})
        } else {
          this.$emit('edited')
        }
      })
          .catch(() => {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('setting.Locale.notifications.edit.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          })
    },
    setCurrentUserLocale() {
      const locale = this.$store.state.locale
      this.languages.forEach((language) => {
        if (locale.code === language.locale) this.locale = language
      })
      const currencyIndex = this.currencyOptions.map(e => e.value).indexOf(this.$store.state.setting.clubInfo.club_currency_code)
      if (currencyIndex > -1) {
        this.currency = this.currencyOptions[currencyIndex]
      } else {
        this.currency = {
          label: this.$t('setting.Locale.currency.other'),
          value: 'NUN'
        }
        this.otherCurrency = {
          value: this.$store.state.setting.clubInfo.club_currency,
          isValid: true
        }
      }

      this.countryCode = {
        value: this.$store.state.setting.clubInfo.club_default_country_code,
        isValid: true
      }

      const regionIndex = this.regionOptions.map(e => e.value).indexOf(this.$store.state.setting.clubInfo.club_locale)
      if (regionIndex > -1) {
        this.region = this.regionOptions[regionIndex]
      }
    },
    removeDuplicates() {
      const val = this.locale
      /*let languages = [
        {
          value: 'en',
          label: 'English',
          flag: 'en.png'
        },
        {
          value: 'fa',
          label: 'فارسی',
          flag: 'fa.png'
        },
        {
          value: 'ar',
          label: 'العربیه',
          flag: 'ar.png'
        }
      ]
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].value === val.value || languages[i].value === val) languages.splice(i, 1)
      }
      this.languages = languages*/
    },
    handleSelectLang(locale) {
      this.discardChanges = true
      this.showCurrencySelect = false
      this.$locale.setLocale(locale.lang)
      this.$store.dispatch('setPageTitle', this.$t('setting.Locale.title'))
      showLoading()
      this.currencyOptions = [
        {
          label: this.$t('setting.Locale.currency.IRT'),
          value: 'IRT'
        },
        {
          label: this.$t('setting.Locale.currency.IRR'),
          value: 'IRR'
        },
        {
          label: this.$t('setting.Locale.currency.USD'),
          value: 'USD'
        },
        {
          label: this.$t('setting.Locale.currency.AED'),
          value: 'AED'
        },
        {
          label: this.$t('setting.Locale.currency.other'),
          value: 'NUN'
        }
      ]
      this.$nextTick(() => {
        this.showCurrencySelect = true
        this.setCurrentUserLocale()
        hideLoading()
      })
    },
    handleClick(id) {
      document.getElementById(id).click()
    },
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    }
  },
  watch: {
    locale: {
      handler () {
        this.removeDuplicates()
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.discardChanges) {
      this.$locale.setLocale(this.defaultLang)
    }
  }
}
</script>

<style lang="scss" scoped>
.locale-setting {
  .locale-setting-fields {
    max-width: 400px;
  }
}
</style>
